/**
 * @Descripttion: 工具集
 * @version: 1.1
 * @LastEditors: yubaoshan
 * @LastEditTime: 2022年4月19日10:58:41
 */
const tool = {}

// localStorage
tool.data = {
	set(table, settings) {
		const _set = JSON.stringify(settings)
		const SNOWYSTRING = table.slice(0, 6) === 'SNOWY_' && table !== 'SNOWY_SYS_BASE_CONFIG'
		if (SNOWYSTRING) {
			let localSetting = JSON.parse(localStorage.getItem('SNOWY_SETTING')) || {}
			let newSetting = {}
			newSetting[table] = _set
			return localStorage.setItem('SNOWY_SETTING', JSON.stringify(Object.assign(localSetting, newSetting)))
		} else return localStorage.setItem(table, _set)
	},
	get(table) {
		const SNOWYSTRING = table.slice(0, 6) === 'SNOWY_' && table !== 'SNOWY_SYS_BASE_CONFIG'
		const SNOWY_SETTING = JSON.parse(localStorage.getItem('SNOWY_SETTING')) || {}
		let data = SNOWYSTRING ? SNOWY_SETTING[table] : localStorage.getItem(table)
		try {
			data = JSON.parse(data)
		} catch (err) {
			return null
		}
		return data
	},
	remove(table) {
		return localStorage.removeItem(table)
	},

	clear() {
		return localStorage.clear()
	}
}

// sessionStorage
tool.session = {
	set(table, settings) {
		const _set = JSON.stringify(settings)
		return sessionStorage.setItem(table, _set)
	},
	get(table) {
		let data = sessionStorage.getItem(table)
		try {
			data = JSON.parse(data)
		} catch (err) {
			return null
		}
		return data
	},
	remove(table) {
		return sessionStorage.removeItem(table)
	},
	clear() {
		return sessionStorage.clear()
	}
}

// 千分符
tool.groupSeparator = (num) => {
	if (!num) {
		return '0'
	} else {
		num = `${num}`
		if (!num.includes('.')) num += '.'
		return num
			.replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
				return `${$1},`
			})
			.replace(/\.$/, '')
	}
}

// 银行卡号格式化
tool.bankCard = (cardNumber) => {
	// 转为字符串确保数据格式并且 移除任何非数字字符
	let cleanedNumber = (cardNumber + '').replace(/\D/g, '')

	// 插入空格，从后往前每隔4位插入
	let formatted = cleanedNumber.replace(/(.{4})/g, '$1 ').trim()

	return formatted
}

// 获取所有字典数组
tool.dictDataAll = () => {
	return tool.data.get('DICT_TYPE_TREE_DATA')
}

// 字典翻译方法，界面插槽使用方法 {{ $TOOL.dictTypeData('sex', record.sex) }}
tool.dictTypeData = (dictValue, value) => {
	const dictTypeTree = tool.dictDataAll()
	if (!dictTypeTree) {
		return '需重新登录'
	}
	const tree = dictTypeTree.find((item) => item.dictValue === dictValue)
	if (!tree) {
		return '无此字典'
	}
	const children = tree.children
	const dict = children.find((item) => item.dictValue === value)
	return dict ? dict.dictLabel : '无此字典项'
}

// 获取某个code下字典的列表，多用于字典下拉框
tool.dictTypeList = (dictValue) => {
	const dictTypeTree = tool.dictDataAll()
	if (!dictTypeTree) {
		return []
	}
	const tree = dictTypeTree.find((item) => item.dictValue === dictValue)
	if (tree && tree.children) {
		return tree.children
	}
	return []
}

// 获取某个code下字典的列表，基于dictTypeList 改进，保留老的，逐步替换
tool.dictList = (dictValue) => {
	const dictTypeTree = tool.dictDataAll()
	if (!dictTypeTree) {
		return []
	}
	const tree = dictTypeTree.find((item) => item.dictValue === dictValue)
	if (tree) {
		return tree.children.map((item) => {
			return {
				value: item['dictValue'],
				label: item['name']
			}
		})
	}
	return []
}

// 获取分页字典的列表值 用于全局分页动态设置
tool.dictPaginationList = () => {
	const dictTypeTree = tool.dictDataAll()
	if (!dictTypeTree) {
		return []
	}
	const tree = dictTypeTree.find((item) => item.dictValue === 'PAGINATION_SETTING')
	if (tree && tree.children) {
		return tree.children.map((x) => x.dictValue)
	}
	return []
}

// 树形翻译 需要指定最顶级的 parentValue  和当级的value
tool.translateTree = (parentValue, value) => {
	const tree = tool.dictDataAll().find((item) => item.dictValue === parentValue)
	const targetNode = findNodeByValue(tree, value)
	return targetNode ? targetNode.dictLabel : ''
}
const findNodeByValue = (node, value) => {
	if (node.dictValue === value) {
		return node
	}
	if (node.children) {
		for (let i = 0; i < node.children.length; i++) {
			const result = findNodeByValue(node.children[i], value)
			if (result) {
				return result
			}
		}
	}
	return null
}

// 生成UUID
tool.snowyUuid = () => {
	let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		let r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
	// 首字符转换成字母
	return 'xn' + uuid.slice(2)
}

// 金额转中文大写
tool.upperMoney = (money) => {
	//汉字的数字
	var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
	//基本单位
	var cnIntRadice = new Array('', '拾', '佰', '仟')
	//对应整数部分扩展单位
	var cnIntUnits = new Array('', '万', '亿', '兆')
	//对应小数部分单位
	var cnDecUnits = new Array('角', '分', '毫', '厘')
	//整数金额时后面跟的字符
	var cnInteger = '整'
	//整型完以后的单位
	var cnIntLast = '元'
	//最大处理的数字
	var maxNum = 999999999999999.9999
	//金额整数部分
	var integerNum
	//金额小数部分
	var decimalNum
	//输出的中文金额字符串
	var chineseStr = ''
	//分离金额后用的数组，预定义
	var parts
	if (money === '') {
		//不能用==
		return ''
	}
	money = parseFloat(money)
	if (money >= maxNum) {
		//超出最大处理数字
		return ''
	}
	if (money == 0) {
		chineseStr = cnNums[0] + cnIntLast + cnInteger
		return chineseStr
	}
	//转换为字符串
	money = money.toString()
	if (money.indexOf('.') == -1) {
		integerNum = money
		decimalNum = ''
	} else {
		parts = money.split('.')
		integerNum = parts[0]
		decimalNum = parts[1].substr(0, 4)
	}
	//获取整型部分转换
	if (parseInt(integerNum, 10) > 0) {
		var zeroCount = 0
		var IntLen = integerNum.length
		for (var i = 0; i < IntLen; i++) {
			var n = integerNum.substr(i, 1)
			var p = IntLen - i - 1
			var q = p / 4
			var m = p % 4
			if (n == '0') {
				zeroCount++
			} else {
				if (zeroCount > 0) {
					chineseStr += cnNums[0]
				}
				//归零
				zeroCount = 0
				chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
			}
			if (m == 0 && zeroCount < 4) {
				chineseStr += cnIntUnits[q]
			}
		}
		chineseStr += cnIntLast
	}
	//小数部分
	if (decimalNum != '') {
		var decLen = decimalNum.length
		for (var i = 0; i < decLen; i++) {
			var n = decimalNum.substr(i, 1)
			if (n != '0') {
				chineseStr += cnNums[Number(n)] + cnDecUnits[i]
			}
		}
	}
	if (chineseStr == '') {
		chineseStr += cnNums[0] + cnIntLast + cnInteger
	} else if (decimalNum == '') {
		chineseStr += cnInteger
	}
	return chineseStr
}

tool.formatTime = (dateStr1, dateStr2) => {
	if ((dateStr1.includes('上午') || dateStr1.includes('下午')) && (dateStr2.includes('上午') || dateStr2.includes('下午'))) {
		//传入的时间格式为 yyyy-MM-DD a  上午、下午
		let time1 = dateStr1.split(' ')
		let time2 = dateStr2.split(' ')
		const diff = Math.abs(new Date(time1[0]) - new Date(time2[0]))
		const days = diff / (1000 * 60 * 60 * 24)
		if (time1[0] === time2[0] && time1[1] === time2[1]) {
			return '0.5天'
		} else if (time1[0] === time2[0] && time1[1] !== time2[1]) {
			return '1天'
		} else if (time1[0] !== time2[0] && time1[1] === time2[1]) {
			return days + 0.5 + '天'
		} else {
			if (time1[1] === '上午' && time2[1] === '下午') {
				return days + 1 + '天'
			} else {
				return days + '天'
			}
		}
	} else if(!dateStr1.includes(' ') && !dateStr2.includes(' ')) {
		//传入的时间格式为 yyyy-MM-DD  只有年月日
		const date1 = new Date(dateStr1)
		const date2 = new Date(dateStr2)
		const diff = Math.abs(date2 - date1)
		let days = diff / (1000 * 60 * 60 * 24)
		return days + 1 + '天'
	} else {
		//传入的时间格式为 yyyy-MM-DD HH:mm:ss  年月日时分秒
		const date1 = new Date(dateStr1)
		const date2 = new Date(dateStr2)
		const diff = Math.abs(date2 - date1) // 计算两个日期的差值（毫秒）
		let hours = diff / (1000 * 60 * 60) // 转换为小时
		return hours.toFixed(0) + '小时'
	}
}

// 提示
tool.tipText = (text) => {
	let divBox = document.createElement('div')
	divBox.id = 'tip-div'
	divBox.style.top = '30px'
	divBox.style.left = '50%'
	divBox.style.width = 'auto'
	divBox.style.color = '#fff'
	divBox.style.zIndex = '999999'
	divBox.style.background = 'red'
	divBox.style.textAlign = 'center'
	divBox.style.borderRadius = '4px'
	divBox.style.position = 'absolute'
	divBox.style.padding = '10px 20px'
	divBox.style.transform = 'translateX(-50%)'
	divBox.innerHTML = text
	document.body.appendChild(divBox)
	setTimeout(() => {
		document.body.removeChild(divBox)
	}, 3000)
	return divBox
}  
export default tool
