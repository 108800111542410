<template>
  <div class="container">
    <section class="window-btn row align-center">
      <div class="min-size cursor" @click="minWindow">
        <i class="el-icon-minus size-icon" title="最小化"></i>
      </div>
      <div class="close-size cursor" @click="closeWindow">
        <i class="el-icon-close size-icon" title="关闭"></i>
      </div>
    </section>
    <section class="login-section">
      <h1 class="login-title">欢迎登录YY伙伴客户端</h1>
      <div class="phone-login" v-if="loginType === 'password'">
        <el-form ref="form" :model="form" :rules="loginType === 'password' ? rules : ''" @submit.native.prevent>
          <el-form-item prop="phone">
            <el-input ref="phone" maxlength="11" v-model="form.phone" placeholder="请输入用户手机号"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input ref="password" v-model="form.password" type="password" placeholder="请输入登录密码"></el-input>
          </el-form-item>
          <div class="row space-between login-password">
            <el-checkbox v-model="remPassword" label="记住密码"></el-checkbox>
          </div>
          <el-button class="submit-btn" native-type="submit" @click.native="submit" :loading="loading"
            >登 录
          </el-button>
        </el-form>
      </div>
      <div class="login-code" v-if="loginType === 'code'">
        <div class="code-text">请使用APP扫描下方二维码，点击"确认授权"即可登录</div>
        <div class="code-image">
          <el-image :src="identifyBase64"></el-image>
          <div class="code-mask" v-if="Number(scanType) > 0">
            <template v-if="scanType === '1'">
              <img class="success-img" src="../assets/images/createsucc.png" />
              <div class="cursor success-btn">扫码成功,请在手机上点击“确认授权”</div>
            </template>
            <template v-if="scanType === '2'">
              <img class="success-img" src="../assets/images/createsucc.png" />
              <div class="success-btn">授权中...</div>
            </template>
            <template v-if="scanType === '3'">
              <div class="login-fail cursor">二维码已过期</div>
              <el-button class="fail-btn" type="info" @click="refreshCode">刷新二维码</el-button>
            </template>
            <template v-if="scanType === '4'">
              <div class="login-fail cursor">登录失败</div>
              <el-button class="fail-btn" type="info" @click="refreshCode">刷新二维码</el-button>
            </template>
          </div>
        </div>
        <p>二维码超时时间：{{ expireTime }}</p>
      </div>
    </section>
    <div class="login-change">
      <img
        v-if="loginType === 'password'"
        class="login-change-img"
        src="../assets/images/code-login.png"
        @click="changeLogin('code')" />
      <img
        v-if="loginType === 'code'"
        class="login-change-img"
        src="../assets/images/phone-login.png"
        @click="changeLogin('password')" />
    </div>
    <section class="row align-center space-center login-mask" v-if="maskVisible">
      <div class="mask-box">
        <div>{{ deptCode }}/{{ deptName }}</div>
        <p>已设置多个收银台，请选择当前收银设备对应的收银台</p>
        <div
          class="row align-center table-line"
          v-for="(item, index) in terminalList"
          :key="index"
          :class="selectItem && selectItem.id === item.id ? 'select-line' : ''"
          @click="selectHandel(item)">
          <div class="row align-center space-center select-circle">
            <span></span>
          </div>
          <span class="line-title">{{ item.title }}</span>
        </div>
        <el-checkbox v-model="rememberSelect" label="在本机记住选择"></el-checkbox>
        <div class="mask-sure cursor" @click="joinHandel">确认并进入</div>
      </div>
    </section>
    <div class="pro-dev" @click="apiDialog = true"></div>
    <section class="row align-center space-center api-mask" v-if="apiDialog">
      <div class="chang-dialog">
        <div class="title-row row align-center space-between">
          <p class="row align-center">
            <i></i>
            <span>客户端环境设置</span>
          </p>
          <i
            class="el-icon-close"
            @click="
              apiDialog = false
              passwordStep = true
              apiPassword = ''
            "></i>
        </div>
        <div class="password-box" v-if="passwordStep">
          <input class="input" placeholder="请输入密码" v-model="apiPassword" size="small" @keyup.enter="stepChange" />
        </div>
        <div class="row align-center label" v-else>
          <span>环境选择：</span>
          <div :class="[apiType === '1' ? 'select' : '']" @click="apiType = '1'">
            <span>正式环境</span>
            <i v-show="apiType === '1'" class="el-icon-check"></i>
          </div>
          <div :class="[apiType === '2' ? 'select' : '']" @click="apiType = '2'">
            <span>公测环境</span>
            <i v-show="apiType === '2'" class="el-icon-check"></i>
          </div>
        </div>
        <el-button type="primary" size="medium" @click="stepChange">确定</el-button>
      </div>
    </section>
  </div>
</template>

<script>
import tool from '@/utils/tool.js'
import moment from 'moment'
import { ipcApiRoute } from '@/api/main'
import { ipc } from '@/utils/ipcRenderer'
import { queryTerminalInfo } from '@/libs/http/modules/posOrder'
import { Login, getOauthScan, oauthScanPreLogin } from '@/libs/http/api.js'
const urlConfig = window.urlConfig
export default {
  data() {
    return {
      form: {
        phone: '',
        password: ''
      },
      rules: {
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }],
        phone: [{ required: true, trigger: 'blur', message: '请输入有效手机号', max: 11 }]
      },
      timer: null,
      scanType: '',
      deptName: '',
      deptCode: '',
      identify: '',
      loading: false,
      expireTime: '',
      terminalList: [],
      selectItem: null,
      maskVisible: false,
      identifyBase64: '',
      remPassword: false,
      loginType: 'password',
      rememberSelect: false,
      passwordStep: true,
      apiDialog: false,
      apiType: '',
      apiPassword: ''
    }
  },
  mounted() {
    document.title = 'YY伙伴-登录'
    if (this.form.phone === '') {
      this.$refs.phone.focus()
    } else {
      this.$refs.password.focus()
    }
    if (this.$cache.local.get('remember') === 'Y') {
      this.remPassword = true
      this.form.phone = this.$cache.local.get('phone')
      this.form.password = this.$cache.local.get('password')
    } else {
      this.form.password = ''
      this.remPassword = false
    }
  },
  methods: {
    minWindow() {
      ipc.invoke(ipcApiRoute.actionMainWindow, 'mini').then(r => {})
    },
    closeWindow() {
      ipc.invoke(ipcApiRoute.actionMainWindow, 'close').then(r => {})
    },
    changeLogin(type) {
      this.loginType = type
      if (type === 'code') {
        this.getCode()
      } else {
        this.scanType = '0'
        clearInterval(this.timer)
        this.timer = null
      }
    },
    getCode() {
      getOauthScan()
        .then(data => {
          this.identify = data.identify
          this.identifyBase64 = data.identifyBase64
          this.expireTime = data.expireTime
          this.timer = setInterval(() => {
            this.getScanState()
          }, 2000)
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    getScanState() {
      if (moment().isAfter(moment(this.expireTime))) {
        this.scanType = '3'
        clearInterval(this.timer)
        this.timer = null
        return
      }
      oauthScanPreLogin({ qrcode: this.identify })
        .then(data => {
          if (data.state === '0') {
            this.scanType = '0'
          }
          if (data.state === '1') {
            this.scanType = '1'
          }
          if (data.state === '2') {
            this.form.phone = data.phone
            clearInterval(this.timer)
            this.timer = null
            this.scanType = '2'
            this.login()
          }
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    refreshCode() {
      this.scanType = '0'
      this.getCode()
    },
    submit() {
      this.$cache.local.set('isRevenue', 'N')
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.login()
        }
      })
    },
    login() {
      this.loading = true
      const reqData = {
        phone: this.form.phone
      }
      if (this.loginType === 'code') {
        reqData['loginType'] = '2'
        reqData['qrcode'] = this.identify
      } else {
        reqData['password'] = this.form.password
      }
      Login(reqData)
        .then(data => {
          //重新登录时清除新接口的token和登录时效
          tool.data.remove('TOKEN')
          tool.data.remove('TOKEN_TIME')

          this.loading = false
          this.$cache.local.set('token', data['accessToken'])
          this.$cache.local.set('wsToken', data['wsToken'])
          this.$cache.local.set('deptId', data['deptId'])
          this.$cache.local.set('deptName', data['deptName'])
          this.$cache.local.set('deptCode', data['erpShopAlias'])
          this.$cache.local.set('saleQuality', data['saleQuality'])
          this.$cache.local.set('marketingUnitId', data['marketingUnitId'])
          this.$cache.local.set('routeList', JSON.stringify(data['permissions']))
          this.$cache.local.set('name', data['name'])
          this.$cache.local.set('phone', data['phone'])
          this.$cache.local.set('userId', data['userId'])
          this.$cache.local.set('avatar', data['avatar'])
          this.$cache.local.set('password', this.form.password)
          if (this.remPassword) {
            this.$cache.local.set('remember', 'Y')
          } else {
            this.$cache.local.set('remember', 'N')
          }
          this.$cache.local.set('maxCount', data['maxCount'])
          this.$cache.local.set('intervalTime', data['intervalTime'])
          this.$cache.local.set('deptReturnCode', data['postPrintInfo']['deptReturnCode'] || 'DPTCD')
          this.$cache.local.set('deptReturnCount', data['postPrintInfo']['deptReturnCount'] || '1')
          this.$cache.local.set('deptAllotCode', data['postPrintInfo']['deptAllotCode'] || 'DPDCD')
          this.$cache.local.set('deptAllotCount', data['postPrintInfo']['deptAllotCount'] || '1')
          this.$cache.local.set('printerModel', data['postPrintInfo']['printerModel'] || 'XP-80C')
          this.$cache.local.set('retailReceiptCode', data['postPrintInfo']['retailReceiptCode'] || 'LSXP')
          this.$cache.local.set('retailReceiptCount', data['postPrintInfo']['retailReceiptCount'] || '1')
          this.$cache.local.set('crmReceiptCode', data['postPrintInfo']['crmReceiptCode'] || 'HYXP')
          this.$cache.local.set('crmReceiptCount', data['postPrintInfo']['crmReceiptCount'] || '1')
          this.$cache.local.set('kdReceiptCode', data['postPrintInfo']['kdReceiptCode'] || 'KDXP')
          this.$cache.local.set('kdReceiptCount', data['postPrintInfo']['kdReceiptCount'] || '1')
          this.$cache.local.set('skuLableReceiptCode', data['postPrintInfo']['skuLableReceiptCode'] || 'SKU-LABLE')
          this.$cache.local.set('skuLableReceiptCount', data['postPrintInfo']['skuLableReceiptCount'] || '1')
          this.$cache.local.set('tjtzReceiptCode', data['postPrintInfo']['tjtzReceiptCode'] || 'TJTZ')
          this.$cache.local.set('tjtzReceiptCount', data['postPrintInfo']['tjtzReceiptCount'] || '1')
          this.deptName = this.$cache.local.get('deptName')
          this.deptCode = this.$cache.local.get('deptCode')
          queryTerminalInfo({
            id: data['marketingUnitId']
          })
            .then(res => {
              if (res && JSON.stringify(res) !== '{}' && res.length > 1) {
                this.terminalList = res
                this.maskHandel()
              } else {
                if (res && res[0]) {
                  this.$cache.local.set('cashierDeskItem', JSON.stringify(res[0]))
                } else {
                  this.$cache.local.set('cashierDeskItem', JSON.stringify(''))
                }
                this.$router.replace('/home')
                setTimeout(() => {
                  ipc.invoke(ipcApiRoute.actionMainWindow, 'max').then(r => {})
                }, 100)
              }
            })
            .catch(error => {
              this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
            })
        })
        .catch(err => {
          this.loading = false
          this.scanType = '4'
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    selectHandel(item) {
      this.selectItem = item
    },
    maskHandel() {
      this.maskVisible = true
      let rememberKey = this.$cache.local.get('rememberSelect')
      if (rememberKey) {
        rememberKey = JSON.parse(this.$cache.local.get('rememberSelect'))
      }
      let remItem = {}
      if (this.$cache.local.get('cashierDeskItem')) {
        remItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
      }
      let res = this.terminalList.some(item => {
        return item.id === remItem.id && item.title === remItem.title
      })
      if (rememberKey && res) {
        this.selectItem = remItem
        this.rememberSelect = rememberKey
      } else {
        this.selectItem = null
        this.rememberSelect = false
      }
    },
    joinHandel() {
      if (!this.selectItem) {
        return this.$message({ type: 'error', message: '请选择一个收银台进入', duration: 2000, showClose: true })
      }
      this.$cache.local.set('cashierDeskItem', JSON.stringify(this.selectItem))
      this.$cache.local.set('rememberSelect', JSON.stringify(this.rememberSelect))
      this.$router.replace('/home')
      setTimeout(() => {
        ipc.invoke(ipcApiRoute.actionMainWindow, 'max').then(r => {})
      }, 100)
    },
    stepChange() {
      const that = this
      if (this.passwordStep) {
        if (this.apiPassword !== '666888')
          return this.$message({
            type: 'error',
            message: '请输入正确的开发者密码',
            duration: 2000,
            showClose: true
          })
        this.apiPassword = ''
        this.passwordStep = false
        ipc
          .invoke(ipcApiRoute.fileCacheGet, {
            cacheName: 'INJECT_ENV'
          })
          .then(r => {
            if (r) {
              if (r === 'production') {
                that.apiType = '1'
              } else {
                that.apiType = '2'
              }
            } else {
              that.apiType = '1'
            }
          })
      } else {
        if (this.apiType === '1') {
          ipc
            .invoke(ipcApiRoute.fileCacheSet, {
              cacheName: 'INJECT_ENV',
              cacheValue: 'production'
            })
            .then(r => {
              if (r) {
                window.location.href = urlConfig.prodPosWebRoot
                ipc.invoke(ipcApiRoute.setDomainName, urlConfig.prodPosWebRoot).then(r => {})
              } else {
                this.$message({
                  type: 'error',
                  message: '切换环境时异常',
                  duration: 2000,
                  showClose: true
                })
              }
            })
        } else {
          ipc
            .invoke(ipcApiRoute.fileCacheSet, {
              cacheName: 'INJECT_ENV',
              cacheValue: 'pre'
            })
            .then(r => {
              if (r) {
                window.location.href = urlConfig.prePosWebRoot
                ipc.invoke(ipcApiRoute.setDomainName, urlConfig.prePosWebRoot).then(r => {})
              } else {
                this.$message({
                  type: 'error',
                  message: '切换环境时异常',
                  duration: 2000,
                  showClose: true
                })
              }
            })
        }
        this.apiDialog = false
        this.passwordStep = true
      }
    }
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style>
.login-section .el-input__inner {
  border: none;
  background: #f6f7fb;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  border-radius: 8px;
  margin-top: 20px;
}
</style>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: #fff;
  background: url('../assets/images/login_bg.jpg');
  background-size: 100% 100%;
  position: relative;
  -webkit-app-region: drag;

  .window-btn {
    -webkit-app-region: no-drag;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;

    .min-size {
      margin-right: 20px;
    }

    .size-icon {
      color: #999;
      font-size: 21px;
    }
  }

  .login-section {
    position: absolute;
    right: 8%;
    top: 28%;
    width: 400px;
    text-align: center;
    -webkit-app-region: no-drag;

    .login-title {
      font-size: 32px;
      letter-spacing: 3px;
      margin-bottom: 40px;
      color: #067cf2;
      font-weight: normal;
      font-family: 'font-Medium';
    }

    .phone-login {
      background: #ffffff;
      width: 350px;
      margin: auto;

      .submit-btn {
        font-family: 'font-Medium';
        width: 100%;
        border-radius: 6px;
        height: 50px;
        font-size: 20px;
        background: #067cf2;
        border: none;
        color: #fff;
        margin-top: 40px;
      }

      .login-password {
        .forget {
          color: #067cf2;
        }
      }
    }

    .login-code {
      text-align: center;
      width: 350px;
      margin: auto;

      .code-text {
        font-size: 16px;
        color: #999;
      }

      .code-image {
        font-size: 0;
        width: 160px;
        height: 160px;
        margin: 50px auto 0;
        position: relative;

        .code-mask {
          position: absolute;
          width: 161px;
          height: 161px;
          background: rgba(0, 0, 0, 0.7);
          top: 0;
          right: 0;
          font-size: 14px;

          .success-img {
            width: 50px;
            height: 50px;
            margin: 20px auto;
          }

          .success-btn {
            color: #fff;
            margin: 0 10px;
          }

          .login-fail {
            color: #fff;
            margin: 35px auto 30px;
          }

          .fail-btn {
            background: #6590dc;
            border: none;
          }
        }
      }

      p {
        margin-top: 50px;
        color: #666;
      }
    }
  }

  .login-change {
    position: absolute;
    bottom: 10px;
    right: 10px;

    .login-change-img {
      width: 70px;
      height: 70px;
      -webkit-app-region: no-drag;
      cursor: pointer;
    }
  }

  .login-mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;

    .mask-box {
      -webkit-app-region: no-drag;
      background: #fff;
      padding: 20px;
      border-radius: 6px;

      > p {
        margin: 10px 0 40px;
      }

      .table-line {
        border: 1px solid #ddd;
        height: 46px;
        padding: 0 10px;
        border-radius: 6px;
        margin-bottom: 10px;
        cursor: pointer;

        .select-circle {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #ddd;
          margin-right: 10px;
        }

        .line-title {
          margin-top: -2px;
        }
      }

      .select-line {
        border: 1px solid #067cf2;
        background: rgb(230, 247, 255);

        .select-circle {
          border: 1px solid #067cf2;

          span {
            background: #067cf2;
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }
        }
      }

      .mask-sure {
        background: #067cf2;
        border-radius: 6px;
        line-height: 45px;
        text-align: center;
        color: #fff;
        width: 260px;
        margin: 50px auto 0;
      }
    }
  }

  .pro-dev {
    width: 120px;
    height: 70px;
    position: fixed;
    left: 30px;
    top: 68px;
    cursor: pointer;
    -webkit-app-region: no-drag;
  }

  .api-mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .chang-dialog {
    -webkit-app-region: no-drag;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    width: 350px;
    height: 250px;

    .title-row {
      border-radius: 8px 8px 0 0;
      background: #f5f5f5;
      padding: 10px;

      p i {
        width: 5px;
        height: 22px;
        background: #0b83f3;
        margin-right: 10px;
        border-radius: 2px;
      }

      > i {
        font-size: 20px;
        cursor: pointer;
        color: #666;
      }
    }

    .password-box {
      height: 150px;
      line-height: 150px;

      .input {
        background: #f5f5f5;
        border: none;
        outline: none;
        line-height: 36px;
        padding: 0 10px;
        width: 320px;
      }
    }

    .label {
      height: 150px;
      line-height: 150px;

      > span {
        margin-left: 15px;
        line-height: normal;
      }

      i {
        margin-left: 5px;
        font-weight: bold;
      }

      div {
        padding: 12px 10px;
        cursor: pointer;
        line-height: normal;
        border: 1px solid #eee;
        margin-right: 10px;
        border-radius: 4px;
      }

      .select {
        border: 1px solid #0b83f3;
        color: #0b83f3;
      }
    }
  }
}
</style>
